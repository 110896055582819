/**
 * Created by Bart Decorte.
 * Date: 25/02/2018
 * Time: 11:36
 */

require([
  'requirejs/require',
  'js/libs/modernizr.min'
], function() {});


define("../resources/assets/js/head", function(){});

